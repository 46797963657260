/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'e2iFteZc3Zs3cnms319eKT.png': { uri: '/images/e2iFteZc3Zs3cnms319eKT.png' },
'r4Kgjk4CRNyphTAxnxLoAJ.png': { uri: '/images/r4Kgjk4CRNyphTAxnxLoAJ.png' },
'1Yp9ynUQVPjM7Rs85seMmb.png': { uri: '/images/1Yp9ynUQVPjM7Rs85seMmb.png' },
'6HfeP91SzQhMPAbaPxUrXB.png': { uri: '/images/6HfeP91SzQhMPAbaPxUrXB.png' },
'vGMzNqDZLQsDvmqR8HfNMk.png': { uri: '/images/vGMzNqDZLQsDvmqR8HfNMk.png' },
'53oL2jwKdFcMgdHjydMu3g.png': { uri: '/images/53oL2jwKdFcMgdHjydMu3g.png' },
'nFnVHpguK58evtWTy6736e.png': { uri: '/images/nFnVHpguK58evtWTy6736e.png' },
'cndi149mzeKJExTR6ZVejk.png': { uri: '/images/cndi149mzeKJExTR6ZVejk.png' },
'pgEjpR6C7JvLHDGc3C1XV5.png': { uri: '/images/pgEjpR6C7JvLHDGc3C1XV5.png' },
'mAyVYdBXA1xEk2RWLdMqmB.png': { uri: '/images/mAyVYdBXA1xEk2RWLdMqmB.png' },
'8SPbWxvzkom2NwQFqXs76j.png': { uri: '/images/8SPbWxvzkom2NwQFqXs76j.png' },
'bXaMccdsKTue5Q92wTgCmX.png': { uri: '/images/bXaMccdsKTue5Q92wTgCmX.png' },
'mzWMdnU2pdjy6gGb6Gfth4.png': { uri: '/images/mzWMdnU2pdjy6gGb6Gfth4.png' },
'thvb7MjSykHN48PL7fBuB7.png': { uri: '/images/thvb7MjSykHN48PL7fBuB7.png' },
'brTDVjnb3mfDzvHsk8ECtj.png': { uri: '/images/brTDVjnb3mfDzvHsk8ECtj.png' },
'h1xCspVZzQUdNBLR4uF74x.png': { uri: '/images/h1xCspVZzQUdNBLR4uF74x.png' },
'b6P8pVZA27eniTdaiugxku.png': { uri: '/images/b6P8pVZA27eniTdaiugxku.png' },
'7jYXeT9REgb7KMfBZ8Rdqh.png': { uri: '/images/7jYXeT9REgb7KMfBZ8Rdqh.png' },
'mRmYiEp1Y9v1wvRS4e4pdh.png': { uri: '/images/mRmYiEp1Y9v1wvRS4e4pdh.png' },
'gH9Ea7bGRhZxvTVmFznLPp.png': { uri: '/images/gH9Ea7bGRhZxvTVmFznLPp.png' },
'iBC9kUvDyGdcaZiyWB6CYj.png': { uri: '/images/iBC9kUvDyGdcaZiyWB6CYj.png' },
'diW41gRkDfefueWACeWzXR.png': { uri: '/images/diW41gRkDfefueWACeWzXR.png' },
'1waTeTtmS8amjm7gMsA9BP.png': { uri: '/images/1waTeTtmS8amjm7gMsA9BP.png' },
'nHkmPcpUmdEdJjJ6QRLKCR.png': { uri: '/images/nHkmPcpUmdEdJjJ6QRLKCR.png' },
'cxjP3XxxzY2MCrZB3ZHC71.png': { uri: '/images/cxjP3XxxzY2MCrZB3ZHC71.png' },
'qwGeeLrGW7n8haHZxDxAfA.png': { uri: '/images/qwGeeLrGW7n8haHZxDxAfA.png' },
'9u7AchKnbefZN33Q8XrGMM.png': { uri: '/images/9u7AchKnbefZN33Q8XrGMM.png' },
'jgDRkSKGf5wDAEV9sb2ZZD.png': { uri: '/images/jgDRkSKGf5wDAEV9sb2ZZD.png' },
'uhFu7soREGVQNRPrrDCoQ7.png': { uri: '/images/uhFu7soREGVQNRPrrDCoQ7.png' },
'vqkL9PzZKob6XAaB5MSFSS.png': { uri: '/images/vqkL9PzZKob6XAaB5MSFSS.png' },
'2P8A2Mmyv41ykn7hxknEYK.jpg': { uri: '/images/2P8A2Mmyv41ykn7hxknEYK.jpg' },
'7USvcKMqpXnnRNZSRiGdRy.jpg': { uri: '/images/7USvcKMqpXnnRNZSRiGdRy.jpg' },
'5mYWPM1PKLf7jUkhYZdmfU.png': { uri: '/images/5mYWPM1PKLf7jUkhYZdmfU.png' },
'2RSYaucGueMSGXHtfG9b9y.jpg': { uri: '/images/2RSYaucGueMSGXHtfG9b9y.jpg' },
'vqFLt4ZswqQyYf1UNfjaYa.jpg': { uri: '/images/vqFLt4ZswqQyYf1UNfjaYa.jpg' },
'aC9j4hgXJbDQNZtZJagazs.jpg': { uri: '/images/aC9j4hgXJbDQNZtZJagazs.jpg' },
'i1c8RRwu7CjQVsM9DW1NqB.jpg': { uri: '/images/i1c8RRwu7CjQVsM9DW1NqB.jpg' },
'7NWmkDs2SnMSmUGc7xqXfY.jpg': { uri: '/images/7NWmkDs2SnMSmUGc7xqXfY.jpg' }
}

export default imageStaticSourcesByFileName
